/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general
@import form-setup
@import form-theme-sakina-svg

// IMPORT - END


// GENERAL - START

form
	width: 100%
	div.DivForm, div.DivFormCKEditor
		padding: $SPACE_MEDIUM
		fieldset
			.divMatFormFieldPeriod
				width: 100%
			border: none
			padding: 0px
			margin-bottom: $SPACE_LITTLE
			margin-top: $SPACE_LITTLE
			div.DivInputHorizontalContainer
				margin: 0px
				padding: 0px
				+setBorderRadius(0px)
				display: flex
				justify-content: space-between
		fieldset:last-of-type
			margin-bottom: 0px
	ul.UlListAsignee
		display: flex
		flex-flow: row wrap
		align-content: flex-start
		justify-content: space-between
		li
			box-sizing: border-box
			margin: 0
			width: 250px
			height: auto

div.DivForm
	div.DivPercentageProgress
		position: relative
		height: 40px
		width: 100%
		+setBoxSizing(border-box)
		+setBorderRadius($RADIUS_LAYOUT_FORM)
		div#divSubPercentageApprovalProgress, div#divSubPercentageConfirmationProgress
			position: absolute
			height: 40px
			width: 0%
			overflow: hidden
			+setBoxSizing(border-box)
			+setBorderRadius($RADIUS_LAYOUT_FORM)
		span
			position: relative
			color: white
			margin-left: 44%
			padding-top: 10px
			+setBoxSizing(border-box)
			+setBorderRadius($RADIUS_LAYOUT_FORM)

div.DivForm, div.DivFormCKEditor
	div.DivCard, div.DivCardFinish
		width: 100%
		height: 100%
		margin-bottom: 10px
		margin-top: 5px
		div.DivContainerIcon
			position: relative
			display: flex
			flex-direction: row
			justify-content: flex-end
			padding: 0px
			div.DivBorderImage
				display: flex
				justify-content: center
				align-items: center
				width: 32px
				height: 32px
				margin-right: 10px
				background-color: white
				+setBorderRadius(50%)
				img.ImageQuote
					width: 26px
					height: 24px
					object-fit: cover
				img.ImageApprove
					width: 26px
					height: 24px
					object-fit: cover

div.DivCard, div.DivCardFinish
	margin-top: $SPACE_SMALL
	margin-bottom: $SPACE_SMALL
	padding-left: $SPACE_MEDIUM
	padding-right: $SPACE_MEDIUM
	padding-bottom: $SPACE_MEDIUM
	padding-top: $SPACE_MEDIUM
	+setBoxSizing(border-box)
	+setBorderRadius($RADIUS_LAYOUT_FORM)

div.DivContainerIcon
	padding-left: 10px
	padding-right: 10px
	padding-bottom: 10px
	padding-top: 10px
	+setBoxSizing(border-box)
	+setBorderRadius($RADIUS_LAYOUT_FORM)

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	margin-top: $SPACE_HEADERGENERAL_TOP
	padding-bottom: $SPACE_HUGE

div.DivVerifierGeneral, div.DivCenterGeneral
	position: relative
	margin: $SPACE_ENORMOUS
	img#imageLogoHeader
		position: inherit !important
		top: 0px
		right: 0px
	div#divMainDashboard
		div.DivContainer
			margin-top: $SPACE_HUGE

div.DivMainGeneral, div.DivMainAsideSearch
	margin-top: $SPACE_MEDIUM
	width: 100%
	height: 100%
	form
		ul.UlNotification
			list-style: none
			li.ListNotification
				display: flex
				padding: $SPACE_HUGE
				margin: $SPACE_SMALL $SPACE_ZERO
				+setBorderRadius($SPACE_LITTLE)
				div.DivTimeNotification
					display: contents
					p
						margin-right: 0px
			li.ListUnRead
				position: relative
				div.DivNotificationAlert
					width: 30px
					height: 30px
					+setBorderRadius(50%)
					background-color: #EB6924
					position: absolute
					top: -15px
					right: -15px

div.DivMainAsideSearch
	display: flex
	flex-wrap: nowrap
	justify-content: space-between
	align-content: stretch
	flex-basis: $WIDTH_LAYOUT_SIGNIN
	div.DivTableListDownload
		margin-left: 0px
		color: black
		table.TableListDetails
			max-width: inherit
			table-layout: inherit
			td:nth-child(1)
				width: 80%
				text-align: left
			td:nth-child(2)
				width: 10%
			td:nth-child(3)
				width: 10%
				padding-right: 0

div.DivContainerHorizontal, div.DivContainerVertical, div.DivContainerListHorizontal
	width: 100%
	height: 100%
	margin-top: $SPACE_MEDIUM
	display: flex
	flex-wrap: nowrap
	div.DivButtonAddDocument
		align-items: center
		margin-top: 0px
		label.LabelAttachment, label.LabelAttachmentSPPA
			margin-right: 10px
			background: transparent
			input[type=button].ButtonAdd
				margin-top: 0px
div.DivContainerHorizontal
	flex-direction: row
	div.DivContainer
		margin-left: $SPACE_SMALL
		margin-right: $SPACE_SMALL
		display: flex
		flex-direction: column
	div.DivContainer:first-of-type
		margin-left: 0px
	div.DivContainer:last-of-type
		margin-right: 0px
div.DivContainerVertical
	flex-direction: column

div.DivForm, div.DivFormBranch, div.DivFormCKEditor
	margin-top: $SPACE_SMALL
	margin-bottom: $SPACE_SMALL
	padding-left: $SPACE_MEDIUM
	padding-right: $SPACE_MEDIUM
	padding-bottom: $SPACE_MEDIUM
	padding-top: $SPACE_MEDIUM
	+setBoxSizing(border-box)
	+setBorderRadius($RADIUS_LAYOUT_FORM)

div.DivFormThreeHorizontalContainer
	display: grid
	grid-template-columns: 1fr 0.8fr 1fr

form div.DivForm fieldset, div.DivForm, div.DivCard, div.DivCardFinish, div.DivFormCKEditor
	div.DivFormHorizontalContainer, div.DivFormVerticalContainer, div.DivFormHorizontalContainerForReimbursement, div.DivFormHorizontalContainerDashboard
		display: flex
		justify-content: space-between
		align-content: stretch
		align-items: baseline
		flex-wrap: wrap // nowrap
		div.DivListChart
			margin-top: $SPACE_MEDIUM
			align-self: flex-start
			ul
				height: $HEIGHT_CHART_LIST
				overflow-y: scroll
	div.DivHorizontalCenterContent
		justify-content: center
		div.DivRadioButtonContainer
			display: flex
			flex-wrap: wrap
			justify-content: center
	div.DivFormHorizontalContainer, div.DivFormHorizontalContainerDashboard
		flex-direction: row
		div.DivForm2Column
			margin-left: $SPACE_LITTLE
			margin-right: $SPACE_LITTLE
		div.DivForm2Column:first-of-type
			margin-left: 0px
		div.DivForm2Column:last-of-type
			margin-right: 0px
		div.DivForm2ColumnNoMargin
			margin-left: 0px
			margin-right: 0px
		div.DivForm2ColumnNoMargin:last-of-type:nth-of-type(odd)
			div.DivImageCapture
				width: 50%
	div.DivFormVerticalContainer
		flex-direction: column
		div.DivForm2Column
			margin-top: $SPACE_LITTLE
			margin-bottom: $SPACE_LITTLE
		div.DivForm2Column:first-of-type
			margin-top: 0px
		div.DivForm2Column:last-of-type
			margin-bottom: 0px
	div.DivForm2Column
		flex-basis: 1
		flex-grow: 1
		flex-shrink: 1
		span.SpanActiveStatus, i.IActiveStatus
			width: $WIDTH_LABEL_POLICYGENERAL_STATUS
			text-align: center
	div.DivForm2Column
		width: 45%
		span.SpanActiveStatus, IActiveStatus
			width: $WIDTH_LABEL_POLICYGENERAL_STATUS
			text-align: center
	div.DivFormHorizontalContainerDashboard
		align-items: center
div.DivForm:first-of-type
	margin-top: 0px

div.DivForm:last-of-type
	margin-bottom: 0px

div.DivButtonHorizontal
	margin-bottom: $SPACE_LITTLE
	margin-top: $SPACE_LITTLE

div.DivDivider
	height: 2px
	width: 100%
	background: $THEME_TERTIARY_LIGHT_COLOR

div.DivItemEnd
	justify-content: flex-end

form.FormRadioButtonCenterContainer
	justify-content: center
form.FormRadioButtonDefaultContainer
	justify-content: flex-start
	align-items: baseline
form.FormRadioButtonCenterContainer, form.FormRadioButtonDefaultContainer
	display: flex
	flex-direction: row
	margin: 0

div.DivLabelHorizontalContainer
	display: flex
	grid-template-columns: repeat(3, auto)
	justify-content: space-around
	label.LabelNumberContainer
		text-align: center
		span.SpanNumberValue
			margin: 20px auto 0 auto
			width: 50px
			height: 50px
			border-radius: 50%
			display: grid
			place-content: center
			color: white
			font-size: 1.8em

div.DivRoundButtonContainer
	display: flex
	justify-content: flex-end
	input[type=button]
		margin: 0px !important
	label
		margin-top: $SPACE_LITTLE !important

div.DivCenterButtonContainer
	display: flex
	align-items: center
	flex-direction: row
	width: 100%
	justify-content: center
	flex-wrap: nowrap
	gap: 11px

div#divBodyClaimProposal
	div#divMainClaimProposalDetailApproval
		div.DivDynamicContainer
			margin-right: $SPACE_SMALL
			form
				margin-right: 0px
			div.DivFormListSurveyModel, div.DivFormListSurveyThirdPartyLiability
				label.LabelClaimLetterStatus, label.LabelClaimSurveyStatus
					a
						border-radius: 5px
						padding: 2px 5px
	div.DivMainAsideSearch
		div.DivDynamicContainer, div.DivTableContainer
			margin-right: $SPACE_SMALL



div#divMainClaimProposalDetailApproval
	div.DivDynamicContainer
		table.TableOwnRetention
			width: 100%
			tr
				td.TdOwnRetentionnFirst
					width: 40%
				td.TdOwnRetentionnSecond
					width: 5%
				td.TdOwnRetentionnThird
					width: 5%
				td.TdOwnRetentionnFourth
					width: 20%
				td.TdOwnRetentionnTotal
					width: 30%

div.DivBodyGeneral
	div#divMainDashboard
		div.DivSingleForm
			width: 100%
			// margin: 0px ($SPACE_BIG*4)
			div.DivTableContainer
				height: auto

div.DivContainerForUpload
	display: flex
	gap: $SPACE_MEDIUM
	align-items: center
	figure
		margin: 0
		img
			width: 36px
			height: auto
			cursor: pointer
	label.LabelAttachment
		margin-top: $SPACE_NARROW !important
	div.DivContainerForAttachment
		margin-left: 0px
		font-size: 16px

div.DivLegends
	color: $THEME_TERTIARY_SOFT_COLOR
	span
		padding: $SPACE_LITTLE $SPACE_SMALL
		+setBorderRadius($SPACE_TINY)

// GENERAL - END


// NAVIGATION - START

nav
	margin-top: $SPACE_NAVIGATION_MARGIN
	position: relative
	width: $SIZE_LAYOUT_PROFILECONTAINER
	figure
		margin-bottom: $SPACE_BIG
		div, img#imageProfilePicture, span#spanProfileNameInitial
			border-style: solid
			+setBorderRadius(50%)
		div
			position: relative
			margin-left: auto
			margin-right: auto
			margin-bottom: $SPACE_MEDIUM
			width: $SIZE_LAYOUT_PROFILEFRAME
			height: $SIZE_LAYOUT_PROFILEFRAME
			padding: $SPACE_TINY
			border-width: $WIDTH_BORDER_PROFILETHIN
			img#imageProfilePicture, span#spanProfileNameInitial
				width: $SIZE_LAYOUT_PROFILEIMAGE
				height: $SIZE_LAYOUT_PROFILEIMAGE
				border-width: $WIDTH_BORDER_PROFILETHICK
			span#spanProfileNameInitial
				width: $WIDTH_LAYOUT_PROFILENAMEINITIAL
				height: $HEIGHT_LAYOUT_PROFILENAMEINITIAL
				padding-top: $SPACE_SMALL
				padding-bottom: $SPACE_SMALL
	ul.ListNavigation
		li.ListItemNavigation
			ul.ListSubNavigation, ul.ListSubNavigationCalculatePremium
				padding-left: $SPACE_SMALL
				li.ListItemSubNavigationRelative
					position: relative
					div.DivNotificationAlert
						width: 30px
						height: 30px
						+setBorderRadius(50%)
						background-color: #EB6924
						position: absolute
						top: -5px
						right: 0
						display: flex
						p
							display: inline-grid
							margin: 5px auto
				li.ListItemSubNavigation
					min-width: $SPACE_NAVIGATION_WIDTH

// NAVIGATION - END


// SIGN IN - START

div#divBodySignIn
	position: relative
	margin: auto
	width: $WIDTH_LAYOUT_SIGNIN
	height: 100%
	display: flex
	align-items: center
	justify-content: center
	flex-direction: column
	header#headerSignIn
		margin-bottom: $SPACE_BIG
		text-align: center
	form.FormSignInShow
		display: none
	form.FormSignInHide
		display: block

div.DiviframeSignIn
	position: absolute
	display: flex
	flex-direction: column
	margin-top: $SPACE_MEDIUM
	margin-bottom: $SPACE_ENORMOUS
	bottom: $SPACE_LITTLE
	left: $SPACE_LITTLE
	margin: 0

// SIGN IN - END


// HOME - START

div#divBodyHome
	position: absolute
	top: 0px
	left: 0px
	right: 0px
	bottom: 0px
	display: flex
	justify-content: flex-start
	flex-direction: column
	perspective: 1000px
	main
		position: absolute
		padding: $SPACE_ENORMOUS
		top: 0px
		left: 0px
		right: 0px
		bottom: 0px
		+setTransformStyle(preserve-3d)

img#imageLogoHeader
	position: absolute
	width: $WIDTH_LAYOUT_LOGOHEADER
	height: $HEIGHT_LAYOUT_LOGOHEADER
	object-fit: cover

div#divBodyHome
	img#imageLogoHeader
		top: $SPACE_ENORMOUS
		right: $SPACE_ENORMOUS

// HOME - END


// ABOUT - START

img#imageLogoAbout
	display: block
	margin-left: auto
	margin-right: auto
	width: $WIDTH_LAYOUT_LOGOABOUT
	height: $HEIGHT_LAYOUT_LOGOABOUT
	object-fit: cover

// ABOUT - END


// DASHBOARD -  START

.ChartBar
	margin-top: $SPACE_MEDIUM
	margin-bottom: $SPACE_MEDIUM

div#divMainDashboard
	div.DivDashboardEmeterai
		display: flex
		justify-content: space-evenly
		fieldset
			width: auto
			p
				width: 300px
				height: 150px
				margin-left: 0px
				justify-content: center
				align-items: center
				display: flex
				border-radius: 10px

div.DivFormHorizontalContainerDashboard
	.ngx-charts-outer
		width: auto !important
		display: flex
		align-items: center
		svg.ngx-charts
			width: 100% !important
		.chart-legend
			width: 100% !important
			header.legend-title
				display: none
			div.legend-wrap
				width: 100%
				ul.legend-labels
					line-height: 100%
					background: transparent
					li
						margin-top: 10px
						margin-bottom: 10px
	div.DivChartDashboardRenewalNotFound
		text-align: center

// DASHBOARD - END


// DYNAMIC CONTAINER - START

div.DivDynamicContainer
	margin-left: $SPACE_SMALL
	width: 100%
	height: 100%
	div.DivTableContainer
		margin-left: 0px !important
	div.DivSubDynamicContainer
		margin-top: $SPACE_MEDIUM
		padding-left: $SPACE_HUGE
		form
			width: 100%
			margin-right: 0px
	div.DivListControl
		display: flex
		justify-content: space-between
		align-items: center
		margin-bottom: $SPACE_MEDIUM
	form:last-of-type
		margin-right: 0px
	form.FormInsertConversation
		margin-bottom: 0px
		div.DivConversationGeneral
			display: flex
			flex-direction: column
			div.DivConversationHeader
				display: flex
				justify-content: space-between
				div.DivConversationInfo
					display: flex
					flex-direction: row
					div.DivConversationInfoDetails, div.DivConversationSPPAInfoDetails
						display: flex
						flex-direction: column
						justify-content: center
					div.DivConversationSPPAInfoDetails
						margin-left: 1em
				div.DivConversationStatus
					span.SpanConversationDivision
						padding: $SPACE_TINY $SPACE_LITTLE
						+setBorderRadius(calc(($RADIUS_LAYOUT_FORM/2)))
			div.DivConversationChatType
				width: 25%
			div.DivConversationContent
				display: flex
				flex-direction: column
				margin-top: $SPACE_LITTLE
				div.DivAttachmentContainer
					z-index: 1
					width: 100%
					box-sizing: border-box
					display: flex
					align-items: center
					border-bottom-left-radius: $RADUIS_LINK_BUTTONCONVERSATION
					border-bottom-right-radius: $RADUIS_LINK_BUTTONCONVERSATION
					margin-top: 0px
					padding-left: $SPACE_LITTLE
					padding-bottom: $SPACE_LITTLE
					height: $HEIGHT_LAYOUT_BUTTONATTACHMENTCONTAINER
				textarea.TextareaConversationContent
					resize: horizontal
			div.DivConversationProfile
				margin-left: $SPACE_MEDIUM
				+setBorderRadius(50%)
	form.FormInsertConversationApprove
		margin-bottom: $SPACE_HUGE
		div.DivConversationGeneral
			display: flex
			flex-direction: column
			div.DivConversationHeader
				display: flex
				justify-content: space-between
				div.DivConversationInfo
					display: flex
					flex-direction: row
					div.DivConversationInfoDetails, div.DivConversationSPPAInfoDetails
						display: flex
						flex-direction: column
						justify-content: center
					div.DivConversationSPPAInfoDetails
						margin-left: 1em
				div.DivConversationStatus
					span.SpanConversationDivision
						padding: $SPACE_TINY $SPACE_LITTLE
						+setBorderRadius(calc(($RADIUS_LAYOUT_FORM/2)))
			div.DivConversationChatType
				width: 25%
			div.DivConversationContent
				display: flex
				flex-direction: column
				margin-top: $SPACE_LITTLE
				div.DivAttachmentContainer
					z-index: 1
					width: 100%
					box-sizing: border-box
					display: flex
					align-items: center
					border-bottom-left-radius: $RADUIS_LINK_BUTTONCONVERSATION
					border-bottom-right-radius: $RADUIS_LINK_BUTTONCONVERSATION
					margin-top: 0px
					padding-left: $SPACE_LITTLE
					padding-bottom: $SPACE_LITTLE
					height: $HEIGHT_LAYOUT_BUTTONATTACHMENTCONTAINER
				textarea.TextareaConversationContent
					resize: horizontal
			div.DivConversationProfile
				margin-left: $SPACE_MEDIUM
				+setBorderRadius(50%)

div.DivTransparantContainer
	padding: $SPACE_HUGE
	margin-top: $SPACE_MEDIUM
	+setBorderRadius($RADIUS_LAYOUT_FORM)

// DYNAMIC CONTAINER - END


// EXTRA - START

div.DivFormHorizontalContainer, div.DivFormHorizontalContainerDashboard
	div.DivForm2Column
		div.DivImageCapture
			img
				display: block
				width: 95%
			video
				display: block
				width: 95%
				height: 90vh

// EXTRA - END


// TABLE - START

div.DivTableContainer
	margin-left: $SPACE_SMALL
	width: 100%
	height: 100%
	div.DivTableControl
		display: flex
		justify-content: space-between
		align-items: center
		div.DivTableSubControl
			display: flex
			justify-content: space-between
			align-items: center
	ul.ListTableThumbnails, table, ul.ListTableTicket, ul.ListTableConversation
		margin-top: $SPACE_LARGE
		margin-bottom: $SPACE_LARGE
	ul.ListTableThumbnails, ul.ListTableTicket, ul.ListTableConversation
		display: flex
		list-style-type: none
		+setBorderRadius($RADIUS_LAYOUT_FORM)
		li
			margin: 0px
			padding: $SPACE_LITTLE
	ul.ListTableThumbnails
		justify-content: flex-start
		align-content: stretch
		align-items: baseline
		flex-wrap: wrap
		li
			width: $WIDTH_TABLE_THUMBNAILSFIGURE
			// height: $HEIGHT_TABLE_THUMBNAILSFIGURE
			height: auto
			figure
				position: relative
				margin-left: auto
				margin-right: auto
				text-align: center
				img
					width: $SIZE_TABLE_THUMBNAILSICON
					height: $SIZE_TABLE_THUMBNAILSICON
					margin-bottom: $SPACE_LITTLE
					border: none
				figcaption
					word-wrap: normal
					white-space: normal
					word-break: break-all
	table
		width: 100%
		thead, tbody
			tr
				td, th
					padding-top: $SPACE_MEDIUM
					padding-left: $SPACE_LITTLE
					padding-right: $SPACE_LITTLE
					padding-bottom: $SPACE_MEDIUM
					word-wrap: normal
					white-space: normal
					word-break: normal
					text-align: center
					figure
						img
							width: $SIZE_TABLE_THUMBNAILSICONSMALL
							height: $SIZE_TABLE_THUMBNAILSICONSMALL
					span.SpanScore
						padding: 10px 14px
						border-radius: 50%
				td:first-of-type, th:first-of-type
					padding-left: $SPACE_MEDIUM
				td:last-of-type, th:last-of-type
					padding-right: $SPACE_MEDIUM
			tr.TableRowSelected
				background-color: #F26F21
				border: none
				gap: 0px
				cursor: pointer
		thead
				th:first-of-type
					+setBorderRadiusLeft($RADIUS_TABLE_GENERAL)
				th:last-of-type
					+setBorderRadiusRight($RADIUS_TABLE_GENERAL)
		tbody
			tr:first-of-type
				td:first-of-type
					+setBorderRadiusTopLeft($RADIUS_TABLE_GENERAL)
				td:last-of-type
					+setBorderRadiusTopRight($RADIUS_TABLE_GENERAL)
			tr:last-of-type
				td:first-of-type
					+setBorderRadiusBottomLeft($RADIUS_TABLE_GENERAL)
				td:last-of-type
					+setBorderRadiusBottomRight($RADIUS_TABLE_GENERAL)
	table.TableListDetails
		padding: 0px
		margin-top: $SPACE_LITTLE
		margin-bottom: 0px
		max-width: $WIDTH_TABLE_ATTACHMENT
		table-layout: fixed
		td
			padding: 0px
			figure
				margin: 0px
				img
					margin-top: $SPACE_TINY
		td:first-of-type
			text-align: right
			padding-left: 0px
		td:nth-child(1)
			width: $WIDTH_TABLE_ATTACHMENTSMALL
		td:nth-child(2)
			width: $WIDTH_TABLE_ATTACHMENTBIG
			overflow: hidden
			text-overflow: ellipsis
		td:nth-child(3)
			width: $WIDTH_TABLE_ATTACHMENTMEDIUM
		td:nth-child(4)
			width: $WIDTH_TABLE_ATTACHMENTSMALL
			padding-right: 0px
	table.TableListDetailsAttachment
		td:last-of-type
			position: relative
			flex-flow: column wrap
			align-items: center
			div.DivMultipleLabelAttachment
				justify-content: center
				gap: $SPACE_TINY
				margin-top: $SPACE_TINY
				margin-bottom: $SPACE_TINY
		td
			div.DivIconAttachment
				display: flex
				align-content: center
				img
					width: 36px
					height: 36px
				span
					margin-top: auto
					margin-bottom: auto
	ul.ListTableTicket, ul.ListTableConversation
		flex-direction: column
		li
			width: 100%
			padding-top: $SPACE_SMALL
			padding-left: $SPACE_MEDIUM
			padding-right: $SPACE_MEDIUM
			padding-bottom: $SPACE_SMALL
			display: flex
			align-items: center
			+setBoxSizing(border-box)
			img
				margin-right: $SPACE_MEDIUM
				width: $SIZE_ICON_LDPI
				height: $SIZE_ICON_LDPI
			div.DivTicketItem, div.DivConversationGeneral
				display: flex
				flex-direction: column
				flex-grow: 1
				div.DivTicketSubItem, div.DivConversationItem, div.DivTicketContentItem
					display: flex
					justify-content: space-between
					dl
						margin: 0px
						flex-grow: 1
						flex-basis: 0
					div.DivConversationStatus
						display: flex
						align-items: flex-start
					div.DivConversationHeader
						display: flex
						flex-flow: row wrap
						align-items: center
						justify-content: flex-start
						div.DivConversationProfile
							+setBorderRadius(50%)
							span.SpanChatProfileNameInitial
								width: $SPACE_BIG
								height: $SPACE_BIG
								display: flex
								align-items: center
								justify-content: center
						div.DivConversationSubHeader
							span.SpanConversationTitle
								max-width: 200px
					div.DivTicketTail
						span.SpanTicketAlert
							width: 7px
							height: 17px
							background-color: red
							margin-bottom: 0
							+setBorderRadius(50%)
							border-style: solid
			div.DivTicketSubItem:first-of-type, div.DivConversationItem:first-of-type
				margin-bottom: $SPACE_TINY
			div.DivTicketSubItem:last-of-type, div.DivConversationItem:last-of-type
				margin-top: $SPACE_TINY
		li:first-of-type
			+setBorderRadiusTop($RADIUS_LAYOUT_FORM)
		li:end-of-type
			+setBorderRadiusBottom($RADIUS_LAYOUT_FORM)
	ul.ListTableConversation
		li
			+setBorderRadius($RADIUS_LAYOUT_FORM)
		li.LiConversationContainerRight, li.LiConversationContainerLeft
			margin-top: $SPACE_MEDIUM
			width: 80%
			+setBoxSizing(border-box)
			div.DivConversationContent
				p
					white-space: pre-wrap
		li.LiConversationContainerRight
			align-self: flex-end
			div.DivConversationItem
				div.DivConversationHeader
					span
						text-align: right
					div.DivConversationProfile
						margin-left: $SPACE_MEDIUM
						margin-right: 0px
		li.LiConversationContainerLeft
			align-self: flex-start
			flex-direction: row-reverse
			div.DivConversationItem
				flex-direction: row-reverse
				div.DivConversationHeader
					flex-direction: row-reverse !important
					span.SpanConversationDate
						text-align: left
					div.DivConversationProfile
						margin-left: 0px
						margin-right: $SPACE_MEDIUM
		li:first-of-type
			margin-top: 0px

div.DivSubDynamicContainer
	div.DivTableContainer
		margin-left: 0px


div.DivCenterGeneral
	div.DivCenterContainer
		div.DivTableContainer
			margin-left: 0px
	div.DivDynamicContainer
		margin-left: 0px

div.DivSubDynamicContainer
	table.TableOwnRetention
		width: 100%
		tr
			td
				width: 30%

div.DivMultipleButton
	display: flex
	flex-direction: row
	justify-content: center
	column-gap: 15px

div.DivNoMargin
	margin-left: 0px


form.FormInsertConversation
	div.DivSubDynamicContainer
		div.DivTableContainer
			table.TableListDetailsAttachment
				tbody
					td.TdAttachmentSPPA
						width: 15%
					td:nth-last-of-type(1)
						width: 15%
					td
						text-align: center
						input[type=button]
							margin: 0px
						input[type=button]:first-of-type
							margin-top: 0px
						input[type=button]:last-of-type
							margin-bottom: 0px
						div.DivMultipleLabelAttachment
							justify-content: center
							label.LabelSPPA
								margin: 0 5px 0 5px


// TABLE - END


// LOADING - START

div#divCurtainLoading
	position: fixed
	top: 0px
	left: 0px
	right: 0px
	bottom: 0px
	display: none
	z-index: 10
	div#divLoadingBar
		width: 0%
		height: $HEIGHT_LOADING_BAR
		display: none
	div#divLoadingMessage, img#imageLoadingCircle
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
	div#divLoadingMessage
		width: 100%
		height: 100%
		img#imageLoadingCircle
			width: 100px
			height: 100px
			background-color: none

// LOADING - END


// NOTIFICATION - START

div#divNotificationInstall, div#divNotificationInstallList
	display: flex
	padding: $SPACE_LITTLE
	box-sizing: border-box
	margin-bottom: $SPACE_LITTLE
div#divNotificationInstall
	height: 100px
	justify-content: space-between
	align-items: center
	width: 100%
	position: fixed
	left: 0px
	bottom: 0px
	right: 0px
div#divNotificationInstallList
	flex-direction: column
	height: auto
	+setBorderRadius($RADIUS_INPUT_GENERAL)


// NOTIFICATION - END


div#divBodyDashboard
	div#divMainDashboard
		width: 100%
		height: 100%
		margin-top: $SPACE_MEDIUM
	div#divMainDashboard
		display: flex
		flex-wrap: nowrap
	div.DivCenterContainer
		margin-top: $SPACE_HUGE
		display: block
		width: 75%
		position: relative
		margin-left: auto
		margin-right: auto
		div.DivForm
			div.DivHeaderHeadline
				display: flex
				justify-content: space-between
				div.DivHeaderStatus
					display: flex
					align-items: flex-start
					justify-content: space-between
					span
						text-align: center
						margin-left: $SPACE_LITTLE
						margin-right: $SPACE_LITTLE
						margin-top: 0px
						padding: $SPACE_LITTLE
						+setBorderRadius($RADIUS_BUTTON_GENERAL)
					span:first-of-type
						margin-left: 0px
					span:last-of-type
						margin-right: 0px
				div.DivSubHeaderHeadline
					label
						margin: 0
					h2
						margin-top: 5px
						margin-bottom: 5px
			fieldset
				margin-top: 0px
				div.DivForm2Column
					div.DivHeaderInfo
						margin-top: $SPACE_LITTLE
						display: flex
						justify-content: space-between
						dl
							margin: 0px
							flex-grow: 1
							flex-basis: 0
							dd
								margin: 0
				div.DivFormHorizontalContainer
					div.DivHeaderList
						align-self: flex-start
						ul
							li
								padding: 5px 10px
								width: fit-content
								margin-right: 0
								margin-bottom: 5px
								margin-top: 5px
								span
									margin: 0
								+setBorderRadius(9px)
							li:first-of-type
								margin-top: 0px
							li:last-of-type
								margin-bottom: 0px

div.DivProfileGeneral
	display: flex
	justify-content: space-between
	div.DivProfileInfo
		display: flex
		flex-direction: row-reverse
		div.DivProfileInfoDetails
			display: flex
			flex-direction: column
			justify-content: center
		div.DivProfilePhoto
			margin-right: $SPACE_SMALL
			+setBorderRadius(50%)
			span.SpanChatProfileNameInitial
				width: $SPACE_BIG
				height: $SPACE_ENORMOUS
				padding-top: $PADDING_SPAN_CONVERSATIONPROFILE
				display: flex
				justify-content: center

// CALCULATE PREMI FORM - START

form.CalculateForm
	width: 50%
	margin-left: auto
	margin-right: auto
	div.DivForm
		padding: $SPACE_MEDIUM
		fieldset
			border: none
			padding: 0px
			margin-bottom: $SPACE_LITTLE
			margin-top: $SPACE_LITTLE
			div.DivInputText
				margin: 0px
				padding: 0px
				+setBorderRadius(0px)
				display: flex
				align-items: flex-end
				span.SpanRupiah
					margin-right: 30px
		fieldset:last-of-type
			margin-bottom: 0px

// CALCULATE PREMI - END


// PRODUCT INFORMATION - START

div.DivTableContainer
	ul.ListTableThumbnails
		li
			div.DivButtonNextCalculate
				display: flex
				justify-content: center
				div.DivButtonHide
					display: none

// PRODUCT INFORMATION - END

/* THIRD PARTY  START */

// SINGLE DROPDOWN - START

div.DivFormSingleDropdown, div.DivFormSingleDropdownDashboard
	.ng-select
		width: 100%
		.ng-select-container
			height: 40px
			width: 100%
			border-radius: $SPACE_LITTLE
			background-repeat: no-repeat
			background-position: right
			background-size: $SPACE_HUGE + 6
			.ng-value-container
				flex-wrap: wrap
				width: 100%
				.ng-value
					padding-right: $SPACE_TINY
					font-size: 14px
					margin: $SPACE_SMALL 0px $SPACE_SMALL $SPACE_LITTLE
					border-radius: $SPACE_TINY
					margin-right: $SPACE_TINY
					display: flex
					line-height: 0px
					align-items: center
					.ng-value-label
						margin-left: 5px
						white-space: normal
						line-height: initial
						margin-top: 0px
				.ng-input>input
					padding-left: $SPACE_LITTLE
	.ng-select-multiple.NgSelectMultipleSinggleDropdown
		margin-bottom: 22.5px
		.ng-select-container
			margin-top: $SPACE_SMALL
			height: auto
			.ng-value-container
				padding: $SPACE_LITTLE
				gap: $SPACE_LITTLE
				+setBorderRadius(12px)
				.ng-value
					z-index: 1
					position: relative
					padding: 4px 38px 4px $SPACE_LITTLE
					margin: $SPACE_ZERO
					border: $BORDER_THIN solid
					+setBorderRadius($SPACE_LITTLE)
					.ng-value-icon
						font-size: $SPACE_ZERO
						position: absolute
						right: $SPACE_LITTLE
						display: inline-block
						width: $SPACE_MEDIUM
						height: $SPACE_MEDIUM
					.ng-value-label
						margin-left: $SPACE_ZERO
				.ng-input
					padding-left: $SPACE_ZERO
					margin-top: $SPACE_ZERO
					input
						height: auto
						margin: $SPACE_ZERO
						+setBorderRadius($SPACE_ZERO)
	.ng-select-multiple.NgSelectMultipleSinggleDropdown, .ng-select.NgSelectSinggleDropdown
		.ng-select-container
			margin-top: $SPACE_SMALL
			.ng-value-container
				.ng-placeholder
					font-size: $FONT_LABEL_PROPERTY
			.ng-clear-wrapper
				display: flex
				align-items: center
				padding-right: 28px
				margin-top: $SPACE_ZERO
				margin-bottom: $SPACE_ZERO
				.ng-clear
					margin-top: $SPACE_ZERO
					margin-bottom: $SPACE_ZERO
		.ng-dropdown-panel
			padding-left: $SPACE_ZERO
			+setBorderRadius($SPACE_LITTLE)
			.ng-dropdown-panel-items
				.ng-option
					margin: $SPACE_ZERO
					+setBorderRadius($SPACE_LITTLE)
					.ng-option-label
						margin: $SPACE_LITTLE
				.ng-option-disabled
					font-family: $URL_FAMILYPRIMARY_PRIMARY
					font-size: $FONT_LABEL_PROPERTY
					margin: $SPACE_LITTLE
	.ng-select.NgSelectSinggleDropdown
		.ng-select-container
			height: $HEIGHT_INPUT_GENERAL
			margin-bottom: 22.5px
			.ng-value-container
				.ng-value
					margin-left: $SPACE_TINY
					margin-bottom: $SPACE_ZERO
					margin-top: $SPACE_ZERO
				.ng-placeholder
					font-size: $FONT_LABEL_PROPERTY
					margin-left: $SPACE_LITTLE
					&:empty
						display: none
		.ng-dropdown-panel
			top: $HEIGHT_INPUT_GENERAL
	.ng-clear-wrapper
		margin-right: $SPACE_LARGE
		height: $SPACE_MEDIUM
	.ng-dropdown-panel
		.ng-dropdown-panel-items
			.ng-option
				box-sizing: border-box
				cursor: pointer
				display: block
				margin-bottom: -$SPACE_SMALL
				margin-left: $SPACE_LITTLE

div.DivFormSigninDropdown
	margin-top: $SPACE_ZERO
	.ng-select.NgSelectSinggleDropdown
		padding-left: $SPACE_NARROW
		width: 200px
		padding-top: $SPACE_ZERO
		margin-top: $SPACE_ZERO
		margin-bottom: $SPACE_ZERO
		.ng-select-container
			margin-top: $SPACE_NARROW
			margin-bottom: $SPACE_NARROW
			.ng-value-container
				position: relative
				.ng-value
					margin-top: $SPACE_NARROW
					margin-bottom: $SPACE_NARROW
					.ng-value-label
						font-size: $FONT_LABEL_PROPERTY
				.ng-input
					display: none
			.ng-clear-wrapper
				margin-top: $SPACE_NARROW
				margin-bottom: $SPACE_NARROW
		.ng-dropdown-panel-items
			.ng-option
				.ng-option-label
					display: block

div.DivFormLongTextDropdown
	.ng-select
		width: 100%
		white-space: normal
		.ng-dropdown-panel
			margin-left: 0px
		.ng-select-container
			height: 60px
			width: 100%
			border-radius: $SPACE_LITTLE
			background-repeat: no-repeat
			background-position: right
			background-size: $SPACE_HUGE + 6
			.ng-value-container
				flex-wrap: wrap
				width: 100%
				display: flex
				align-items: center
				.ng-value
					padding-right: $SPACE_TINY
					font-size: 14px
					margin: $SPACE_SMALL 0px $SPACE_SMALL $SPACE_LITTLE
					border-radius: $SPACE_TINY
					margin-right: $SPACE_TINY
					display: flex
					line-height: 0px
					align-items: center
					height: fit-content
					.ng-value-label
						margin-left: 5px
						white-space: normal
						line-height: initial
						margin-top: 0px
					span
						margin-bottom: 0px
				.ng-input>input
					padding-left: $SPACE_LITTLE
					margin-top: 0px
	.ng-clear-wrapper
		margin-right: $SPACE_LARGE
		height: $SPACE_MEDIUM
	.ng-dropdown-panel
		margin-left: 0px
		.ng-dropdown-panel-items
			.ng-option
				box-sizing: border-box
				cursor: pointer
				display: block
				margin-bottom: -$SPACE_SMALL
				margin-left: $SPACE_LITTLE

//SINGLE  DROPDOWN - END

// MULTIPLE DROPDOWN - START

div.DivFormMultipleDropdown
	.ng-select
		width: 100%
		.ng-select-container
			width: 100%
			border-radius: $SPACE_LITTLE
			background-repeat: no-repeat
			background-position: right
			background-size: $SPACE_HUGE
			.ng-value-container
				flex-wrap: wrap
				width: 100%
				padding: 5px
				.ng-value
					padding-right: $SPACE_TINY
					font-size: 14px
					margin: $SPACE_TINY
					border-radius: $SPACE_TINY
					display: flex
					line-height: 0px
					align-items: center
					height: fit-content
					.ng-value-icon
						display: inline-block
						padding: $SPACE_TINY $SPACE_TINY
						padding-top: 10px
						background-color: #F7931E
					.ng-value-icon.left
						// margin-right: $SPACE_TINY
						margin-left: -$SPACE_LITTLE
						margin-top: 0px
						margin-bottom: 0px
						border-radius: 50%
						height: 20px
						border: none
						opacity: 0%
					.ng-value-label
						margin-left: 5px
						white-space: normal
						line-height: initial
						padding-top: 10px
						padding-bottom: 10px
					span
						margin-bottom: 0px
						margin-top: 0px
				.ng-value::before
					content: "-"
					width: 10px
					height: 10px
					margin-left: 5px
					margin-right: -10px
					background-color: #F7931E
					+setBorderRadius($SPACE_LITTLE)
					color: white
					text-align: center
					padding-left: 5px
					padding-right: 5px
					padding-top: 8px
					padding-bottom: 2px
					font-size: 2em
				.ng-input>input
					margin-left: $SPACE_TINY
		.ng-clear-wrapper
			position: relative
			margin-top: auto
			margin-bottom: auto
			margin-right: 25px
			padding: 0px
			span
				margin-bottom: 0px
				margin-top: 0px
				padding-left: 7px
	.ng-dropdown-panel
		.ng-dropdown-panel-items
			.ng-option
				box-sizing: border-box
				cursor: pointer
				display: block
				margin-bottom: -$SPACE_SMALL
				margin-left: $SPACE_LITTLE

// MULTIPLE DRODOWN - END

// NGX-MAT-TIMEPICKER START

div.DivForm, form div.DivForm fieldset
	table.ngx-mat-timepicker-table
		tbody.ngx-mat-timepicker-tbody
			td.ngx-mat-timepicker-spacer
				padding-top: $SPACE_LITTLE
			div, mat-mdc-form-field
				margin: 0px
				padding: 0px
				tr
					td
						height: 35px


// NGX-MAT-TIMEPICKER END

/* THIRD PARTY  END */


// CONTAINER GOOGLE MAPS PLATFORM START

	// CARD FOR BRANCH MENU DETAIL START //

div.DivFormBranch
	border-radius: 12px
	div.DivBranchCardInformation
		display: flex
		align-content: center
		margin-top: $SPACE_LITTLE
		img#imageLocation, img#imagePhone, img#imageEmail
			width: $SPACE_MEDIUM
			height: $SPACE_MEDIUM
			margin-right: $SPACE_LITTLE

	// CARD FOR BRANCH MENU DETAIL END //


	// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //

#divMainDashboard
	div.DivFormCardInside
		position: absolute
		margin: $SPACE_MEDIUM
		width: 30%
		z-index: 1
		div.DivFormBranch
			div.DivBranchCardInformation
				img
					border-radius: 50%
					padding: $SPACE_TINY
	div.DivFormNoBorder
		google-map
			.map-container
				border-radius: $SPACE_SMALL


	// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //


// CONTAINER GOOGLE MAPS PLATFORM END


// Reimbursment - Start

div#divBodyDashboard
	form.FormReimbursement
		width: 25%
	div.DivTableReimbursment
		width: 100%


div.DivFormHorizontalContainerForReimbursement
	div.DivForm2Column
		flex: 1
		p
			font-size: 14px !important
			font-style: italic
			margin-top: -10px !important
			font-family: $URL_FAMILYPRIMARY_PRIMARY !important

div.DivForm
	div.DivRadioButtonContainer
		input#checkboxReimbursementDetail
			min-width: 15px
		label
			margin-left: 10px

// Reimbursment - End


// CONTAINER FILE ATTACHMENT DETAIL START

div.DivForm
	fieldset
		p
			font-size: $SPACE_LITTLE
	div.DivContainerAttachedment
		ul
			display: flex
			flex-wrap: wrap
			li
				margin-left: $SPACE_MEDIUM
				margin: 0px
				padding: $SPACE_LITTLE
				figure
					margin: 0px
					padding: 0px
				figcaption
					width: 100px
					word-break: break-word
					img
						width: 100px
				div.DivButton
					display: flex
					justify-content: center

	div#divFormAttachment
		div.DivAttachmentContainer
			justify-content: flex-start
			align-items: center
			div.DivFormVerticalContainer:nth-child(1)
				margin-right: $SPACE_LITTLE
			div.DivFormVerticalContainer:nth-child(2)
				margin-left: $SPACE_LITTLE
				label, span
					margin: $SPACE_TINY
				span
					margin-top: auto
				label
					margin-bottom: auto
			div.DivFormVerticalContainer
				figure.FigureAttachment
					margin: 0 $SPACE_LITTLE
					cursor: pointer
					img
						display: block
						margin: auto
						width: calc($SIZE_ICON_LDPI * 1.5)
						height: calc($SIZE_ICON_LDPI * 1.5)
				div.DivFormHorizontalContainer
					align-items: center
					span, label
						margin: $SPACE_TINY 0
		div.DivTableContainer
			margin-left: 0
			table.TableListDetails
				td:nth-child(1)
					width: $HEIGHT_INPUT_GENERAL
					figure
						width: 100%
					img
						display: block
						margin: auto
				td
					p
						margin: auto


// CONTAINER FILE ATTACHMENT DETAIL END

// DIVFORM CHART

form.FormGenerateTable
	div.DivForm
		div.ngx-charts-outer
			svg.ngx-charts
				width: 350px
				g.pie-chart
					transform: translate(150px, 120px)
		ngx-charts-legend
			height: inherit
			div
				width: 250px !important
				height: inherit
				display: flex
				div.legend-wrap
					margin: auto
					height: auto

// DIVFORM CHART

// SPPA EXTRA OTHER DOCUMENT START

div.DivForm
	fieldset
		div.DivSPPAOtherDocumentContainer
			display: flex
			width: 100%
			div.DivButtonSPAADocument
				display: flex
				align-items: center
				div.DivButton
					display: flex
					justify-content: center
					align-items: center
			div.DivIconSPPADocument
				display: flex
				justify-content: center
				margin: 10px
				margin-left: 20px
				figure
					margin: 0
					img
						width: 50px
						height: 50px
			div.DivNameSPPADocument
				display: flex
				justify-content: center
				align-self: center
				flex-direction: column
				span.SpanNameDocument
					margin: 0
					text-align: bottom
					font-size: $FONT_LABEL_SPAN
					font-weight: 600
					white-space: normal
					word-break: break-word
				label
					font-size: smaller
					text-align: top
					white-space: normal
					margin: 0
					word-break: break-word
		div.DivNoteOtherDocument
			label
				margin-left: 10px
				font-size: $FONT_LABEL_GENERAL
			span
				margin-left: 10px
				font-size: $FONT_LABEL_SUBTITLE

// SPPA EXTRA OTHER DOCUMENT END


// Form Field Year and Month Start

form
	div.DivForm
		div.DivMatFormFieldYearAndMonth, div.DivMatFormFieldYearAndMonthDashboard
			.mat-mdc-form-field
				width: 100%

// Form Field Year and Month End


// CKEditor Start

div.DivSubDynamicContainer
	div.DivFormCKEditor
		div.DivFormCKEditorNote
			margin: 15px 0 15px 0
			ckeditor
				.cke
					.cke_inner
						.cke_contents
							.cke_wysiwyg_div
								padding: 8px

div.DivFormCKEditor
	ol, ul, p
		font-family: inherit
		font-size: revert !important
		font-weight: revert !important
		color: revert !important
		text-decoration: revert !important
		text-transform: revert !important
		padding-left: revert !important
		padding-right: revert !important
		font-family: $URL_FAMILYPRIMARY_PRIMARY !important
		li
			padding-left: revert !important
			padding-right: revert !important

// CKEditor End


// FORM 3 Button - START

div.DivSubDynamicContainer
	form
		div.DivForm
			div.DivFormHorizontalContainer
				div.DivForm3ColumnButton
					width: 30%

form.FormInsertConversation
	div.DivSubDynamicContainer
		div.DivForm2ColumnButton
				display: flex
				flex-direction: row-reverse
				gap: 10px
				input[type=button]
					margin: 10px 0

// FORM 3 Button - END


// SPPA APPROVAL DEPRECIATION - START

div.DivDepreciation
	div.DivForm2Column:nth-child(3), div.DivForm2Column:nth-child(5), div.DivForm2Column:nth-child(7), div.DivForm2Column:nth-child(9)
		margin-left: 0px !important

// SPPA APPROVAL DEPRECIATION - END


// TABLE CENTER - START

div.DivTableContainer
	table.TableCenter
		text-align: center

div.DivResetMargin
	margin: 0

// TABLE CENTER - END


// TABLE SCROLL - START

div.DivTableContainerScroll
	::-webkit-scrollbar
		height: $SPACE_LITTLE
	::-webkit-scrollbar-track
		background: #D9D9D9
		border-radius: $SPACE_LITTLE
	::-webkit-scrollbar-thumb
		background-color: #B8B8B8
		border: $SPACE_THIN solid #D9D9D9
		border-radius: $SPACE_LITTLE
div.DivTableScroll
	margin-bottom: $SPACE_LARGE
	overflow: auto
	table
		margin-bottom: $SPACE_TINY
		text-align: center
		tr
			td.tdDateFormat
				min-width: 150px
			td.tdDescription
				text-align: start
				min-width: 500px

div.DivTableOverflow
	overflow: auto
	::-webkit-scrollbar
		display: none
// TABLE SCROLL - END


// POLICY RENEWAL CONFIRMATION - START

div.DivLittleMargin
	margin-top: $SPACE_LITTLE
	margin-bottom: $SPACE_LITTLE

// POLICY RENEWAL CONFIRMATION - END


// START - HISTORY PROGRESS

div#divHistoryProgressStepper
	Table.TableStepHistory
		tr.TableRowProgress
			display: flex
			margin: 10px 0px
			width: auto
			min-height: 50px
			margin-bottom: 15px
			td.TableDataProgressDate
				display: flex
				margin-right: 20px
				padding-right: 20px
				max-width: 300px
				min-width: 200px
				border-right: solid 2px #000
				p
					margin: auto auto auto $SPACE_ZERO
			hr
				margin: 0 $SPACE_MEDIUM
				border: 1px solid grey
				border-radius: 2px
			td.TableDataProgressDetail
				display: flex
				flex-direction: column
				max-width: 1000px
				p
					margin: auto 0px
				p.ParagraphNoteProgress
					margin: auto 0px
				p:nth-child(2)
					margin-top: 7.5px

// END - HISTORY PROGRESS


// START - TABLE CHECK LIST

table.TableCheckList
	width: 100%
	thead
		tr
			height: 50px
			td
				font-weight: bold
				text-align: center
			td:first-of-type
				width: 18%
			td:nth-child(2)
				width: 25%
	tbody
		tr
			height: 50px
			td
				text-align: center
				div.DivCheck
					width: 8px
					height: 16px
					border-bottom-style: solid
					border-right-style: solid
					border-right-width: 3px
					border-bottom-width: 3px
					position: relative
					margin: auto
					+setRotateZ(45)

// END - TABLE CHECK LIST

// START - DASHBOARD RENEWAL

div.DivContainerDashboardRenewal
	width: 100%
	div.DivFormSearchDashboardRenewal
		margin-bottom: 0px
		margin: 0px
		padding: 0
		background-color: transparent
		div.DivSearchInformation
			label
				font-size: 14px !important
			span
				display: contents !important
div.DivMarginTopBottomAuto
	display: block !important
	margin-top: auto
	margin-bottom: auto
	margin-left: 50px

div.DivJustifyStart
	justify-content: start !important

// END - DASHBOARD RENEWAL


// GCG ONLINE - START

div#divBodyDashboard
	div#divMainDashboard
		div.DivSingleForm
			form
				div.DivButtonContainer
					display: flex
					flex-wrap: wrap
					justify-content: center
					column-gap: 2%
					margin-top: $SPACE_MEDIUM

div.DivMainGCGOnlineOverview
	div.DivSingleForm
		form
			div.DivForm
				+setScrolllbarWidthHeight($WIDTH_SCROLLBAR_VERTICAL, 100%)
				h3
					text-align: center
				fieldset.FieldsetContainerOverview
					max-height: 50vh
					overflow-y: scroll
				div.DivCheckBoxContainer
					margin-top: $SPACE_LITTLE

div.DivMainGCGOnlineDetail
	div.DivSingleForm
		div.DivDynamicContainer
			margin-left: $SPACE_ZERO
		form
			div.DivForm
				label
					span.SpanStatusHistoryApproval
						margin-left: $SPACE_LITTLE
						display: inline-block
						padding: $SPACE_NARROW $SPACE_LARGE
						+setBorderRadius($SPACE_TINY)
				div.DivContainerForUpload
					div.DivButton
						input[type="button"]
							margin-top: $SPACE_LITTLE
							margin-bottom: $SPACE_LITTLE

div.DivMainGCGOnlineOverview div.DivSingleForm form div.DivForm fieldset,
div.DivMainGCGOnlineDetail div.DivSingleForm form div.DivForm fieldset.FieldsetContainerList
	padding: $SPACE_LITTLE $SPACE_LARGE
	box-sizing: border-box

// GCG ONLINE - END


/* E-MATERAI - START */

div.DivBodyEmaterai
	display: flex
	flex-direction: column

div.DivButtonDashboardEmaterai
	display: flex
	justify-content: space-between
	margin-top: 20px

div.DivSingleForm
	div.DivFormVerticalContainer
		label.LabelEmateraiSurveyStatus
			a
				+setBorderRadius($SPACE_TINY)
				padding: 2px 25px


// PDF Viewer - Start

div.DivTableContainer
	#divPdf
		margin: auto
		position: relative
		#divBox
			width: 80px
			height: 80px
			color: rgba(0, 0, 0, 0.87)
			cursor: move
			display: flex
			justify-content: center
			align-items: center
			background-size: contain
			background-repeat: no-repeat
			background-position-x: center
			position: relative
			z-index: 1
		.DivPdfViewer
			position: absolute
			display: block
			height: 100%
			width: 100%
			.ng2-pdf-viewer-container
				height: 100% !important
				overflow-x: unset !important
				.pdfViewer
					height: 100% !important
					.page
						height: 100% !important
						width: 100% !important
						margin: 0px !important
						border: 0px !important
						canvas
							width: 100% !important
							height: 100% !important
						.canvasWrapper
							width: 100% !important
							height: 100% !important
						.textLayer
							width: 100% !important
							height: 100% !important
	div.DivTableControlMaterai
		margin-bottom: 1em
		div.DivTableSubControlMaterai
			display: flex
			align-items: center
			margin: auto
			width: 250px
			input[type=button]
				margin-top: 0
			input[type=button]:first-of-type
				margin-top: 0
			input[type=button]:last-of-type
				margin-bottom: 0

div.DivTransparent
	margin: auto !important
	background-color: rgba(255, 255, 255, 0) !important

// PDF Viewer - End

/* E-MATERAI - END */


/* JUSTIFY SPACE EVENLY - START */

div.DivSubDynamicContainer
	div.DivForm
		fieldset
			div.DivSpaceEvenly
				justify-content: space-evenly !important

/* JUSTIFY SPACE EVENLY - END */